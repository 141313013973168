import { Layout } from 'antd';
import React, { ReactNode } from 'react';

import Header from './Header';
import Sider from './Sider';

const { Content } = Layout;

const AppLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout>
        <Sider />
        <Layout>
          <Content className="p-6 mt-0 mx-6 mb-6 bg-white border-solid border border-gray-400 rounded-[20px]">
            <div className="mx-auto">{children}</div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
