import { useFirebaseApp } from '@admin/common/components/contexts/firebaseApp';
import ShowcaseMenu from '@admin/features/showcase';
import Switcher from '@admin/features/switcher';
import { selectFirebaseUUID, setFirebaseUUID } from '@admin/features/user';
import { useAppDispatch, useAppSelector } from '@admin/settings/hooks';
import { useApolloClient, useQuery } from '@apollo/client';
import { GET_ME, Me, signOut } from '@frontend/data-access';
import { NotificationBell } from '@frontend/notification';
import { Avatar, Dropdown, Layout, MenuProps } from 'antd';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import getConfig from 'next/config';
import Image from 'next/image';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useMemo } from 'react';

const Header = () => {
  const { t: tcommon } = useTranslation('common');
  const { publicRuntimeConfig } = getConfig();
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const firebaseUser = useAppSelector(selectFirebaseUUID);
  const { data } = useQuery<{ me: Me }>(GET_ME);
  const firebaseApp = useFirebaseApp();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a className="!text-red-500" onClick={() => logout()}>
          {tcommon('logout')}
        </a>
      ),
    },
  ];

  const logout = async () => {
    try {
      const response = await signOut();
      if (response) {
        await client.clearStore();
        window.location.reload();
      }
    } catch (error) {
      console.log('logout error: ', error);
    }
  };

  const { data: meData } = useQuery<{
    me: {
      id: number;
      authToken: string;
    };
  }>(GET_ME);

  const fetchToken = useCallback(async () => {
    try {
      const auth = getAuth();
      const userCredential = await signInWithCustomToken(
        auth,
        `${meData?.me.authToken}`
      );
      const user = userCredential.user;
      dispatch(setFirebaseUUID(user?.uid));
    } catch (error) {
      console.log('Firebase auth error');
    }
  }, [meData, dispatch]);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  const isAdmin = useMemo(() => data?.me?.role === 'ADMIN', [data?.me?.role]);

  return (
    <Layout.Header className="flex items-center justify-between">
      <Link href="/">
        <Image
          src={`${publicRuntimeConfig.staticFolder}/logo-full.svg`}
          priority
          height="40"
          width="166"
          alt="SCV logo"
        />
      </Link>
      <div className="flex items-center gap-3">
        <Switcher />
        {isAdmin && <ShowcaseMenu />}
        <NotificationBell
          firebaseUser={firebaseUser}
          firebaseApp={firebaseApp}
        />
        <Dropdown menu={{ items }} trigger={['click']} placement="topLeft">
          <Avatar
            size={32}
            className="!cursor-pointer"
            src={data?.me?.image?.url}
          >
            {data?.me?.fullName.charAt(0)}
          </Avatar>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

export default Header;
