import { SwapOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_ME, Me } from '@frontend/data-access';
import { Button } from 'antd';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

const PLATFORM_URL =
  (process.env['NEXT_PUBLIC_PLATFORM_URL'] || '/') +
  (process.env['NEXT_PUBLIC_PLATFORM_BASE_PATH'] || '');

const Switcher = () => {
  const { t: tcommon } = useTranslation('common');
  const { data } = useQuery<{ me: Me }>(GET_ME);

  if (data?.me.role === 'ADMIN' || data?.me.role === 'CHALLENGE_MANAGER')
    return (
      <Link href={PLATFORM_URL}>
        <Button
          type="primary"
          size="middle"
          icon={<SwapOutlined />}
          shape="round"
        >
          {tcommon('switch_to_user')}
        </Button>
      </Link>
    );
  else return null;
};

export default Switcher;
