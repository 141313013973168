import { useQuery } from '@apollo/client';
import { GET_ME, Me, ShowcaseType } from '@frontend/data-access';
import { Button, Modal, Segmented } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';

import CompanyShowcaseList from './list';

function ShowcaseMenu() {
  const { t } = useTranslation('admin');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [segmentMode, setSegmentMode] = useState<ShowcaseType>('BRIDGE');
  const { data: meData } = useQuery<{ me: Me }>(GET_ME);

  const handleOpenModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleSegmentMode = (value: ShowcaseType) => {
    setSegmentMode(value);
  };
  if (meData?.me?.role !== 'ADMIN') return null;

  return (
    <div>
      <Button shape="round" onClick={handleOpenModal}>
        {t('edit_showcase')}
      </Button>
      <Modal
        title={t('edit_showcase')}
        open={isOpenModal}
        onCancel={handleOpenModal}
        width={750}
        footer={null}
      >
        <div>
          <Segmented
            defaultValue={segmentMode}
            options={[
              {
                label: 'Bridge carousel',
                value: 'BRIDGE',
              },
              {
                label: 'Innovation carousel',
                value: 'INNOVATION',
              },
            ]}
            onResize={undefined}
            onResizeCapture={undefined}
            block
            onChange={handleSegmentMode}
          />
          <CompanyShowcaseList type={segmentMode} />
        </div>
      </Modal>
    </div>
  );
}

export default ShowcaseMenu;
