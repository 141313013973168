import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { GET_SHOWCASES, Showcase, ShowcaseType } from '@frontend/data-access';
import { Button, Empty, Modal, Spin } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useState } from 'react';

import ShowcaseEdit from '../edit';
import CompanyShowcaseCard from './card';

function CompanyShowcaseList({ type }: { type: ShowcaseType }) {
  const { t } = useTranslation('admin');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [getData, { data, loading }] = useLazyQuery<{
    showcases: Showcase[];
  }>(GET_SHOWCASES);

  const handleOpenModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const AddButton = () => {
    return (
      <Button
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
        onClick={handleOpenModal}
      >
        {t('add_new_showcase')}
      </Button>
    );
  };

  useEffect(() => {
    if (type) {
      getData({
        variables: {
          types: [type],
        },
      });
    }
  }, [type]);

  if (loading) {
    return (
      <div className="p-6 text-center">
        <Spin />
      </div>
    );
  }

  return (
    <div className="py-3 space-y-2">
      {data?.showcases?.length > 0 ? (
        <div className="space-y-3">
          <div className="text-right">
            <AddButton />
          </div>
          {data?.showcases.map((item, index) => (
            <CompanyShowcaseCard key={index} data={item} />
          ))}
        </div>
      ) : (
        <div className="text-center">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          <AddButton />
        </div>
      )}

      <Modal
        title={t('add_showcase')}
        open={isOpenModal}
        onCancel={handleOpenModal}
        bodyStyle={{
          padding: 0,
        }}
        footer={null}
      >
        <ShowcaseEdit
          showcaseType={type}
          onFinish={handleOpenModal}
          onCancel={handleOpenModal}
        />
      </Modal>
    </div>
  );
}

export default CompanyShowcaseList;
