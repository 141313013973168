import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_SHOWCASE,
  ErrorResponse,
  GET_SHOWCASE_BY_ID,
  GET_SHOWCASES,
  PATCH_SHOWCASE,
  Showcase,
  ShowcaseType,
  UploadedFile,
} from '@frontend/data-access';
import { ImageUpload } from '@frontend/shared/ui';
import { Button, Form, Input, notification, Select, Spin } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';

interface FormValues {
  title: string;
  description: string;
  label: string;
  bannerData: UploadedFile;
  link: string;
  buttonName: string;
}

const showcaseLabelOptions = [
  { label: 'Company', value: 'COMPANY' },
  { label: 'Post', value: 'POST' },
  { label: 'Open Challenge', value: 'OPEN_CHALLENGE' },
  { label: 'Intra-Challenge', value: 'INTRA_CHALLENGE' },
  { label: 'Pitch', value: 'PITCH' },
  { label: 'Idea', value: 'IDEA' },
  { label: 'POC', value: 'POC' },
];

function ShowcaseEdit({
  showcaseId,
  showcaseType,
  onFinish,
  onCancel,
}: {
  showcaseId?: string;
  showcaseType: ShowcaseType;
  onFinish: () => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation('admin');
  const { t: tcommon } = useTranslation('common');
  const [form] = Form.useForm();
  const [getData, { data, loading }] = useLazyQuery<{
    showcase: Showcase;
  }>(GET_SHOWCASE_BY_ID, {
    variables: {
      id: showcaseId,
    },
  });
  const handleCompleted = () => {
    form.resetFields();
    onFinish();
    notification.success({
      message: showcaseId
        ? tcommon('update_success')
        : tcommon('create_success'),
      placement: 'bottomLeft',
    });
  };
  const handleError = (error: ErrorResponse) => {
    notification.error({
      message: 'Error updating post status',
      icon: (
        <span className="text-red-600">
          <CloseCircleOutlined />
        </span>
      ),
      placement: 'bottomLeft',
    });
  };
  const [createShowcase, { loading: createLoading }] = useMutation(
    CREATE_SHOWCASE,
    {
      onCompleted: handleCompleted,
      onError: handleError,
      refetchQueries: [
        { query: GET_SHOWCASES, variables: { types: [showcaseType] } },
      ],
    }
  );
  const [patchShowcase, { loading: patchLoading }] = useMutation(
    PATCH_SHOWCASE,
    { onCompleted: handleCompleted, onError: handleError }
  );

  const handleFormChange = () => {
    //
  };

  const handleOnFinish = (values: FormValues) => {
    if (showcaseId) {
      patchShowcase({
        variables: {
          input: {
            id: showcaseId,
            type: showcaseType,
            ...values,
          },
        },
      });
    } else {
      createShowcase({
        variables: {
          input: {
            type: showcaseType,
            ...values,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (data?.showcase) {
      form.setFieldsValue({
        title: data.showcase.title,
        description: data.showcase.description,
        label: data.showcase.label,
        bannerData: data.showcase.banner,
        link: data.showcase.link,
        buttonName: data.showcase.buttonName,
      });
    }
  }, [data, form]);

  useEffect(() => {
    if (showcaseId) {
      getData();
    }
  }, [getData, showcaseId]);

  if (loading) {
    return (
      <div className="p-6 text-center">
        <Spin />
      </div>
    );
  }

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={handleFormChange}
        onFinish={handleOnFinish}
      >
        <div className="p-6">
          <Form.Item
            label={t('showcase_title')}
            name="title"
            rules={[{ required: true, message: t('input_required_message') }]}
          >
            <Input placeholder={t('showcase_title_placeholder')} />
          </Form.Item>
          <Form.Item
            label={t('showcase_description')}
            name="description"
            rules={[{ required: true, message: t('input_required_message') }]}
          >
            <Input.TextArea
              rows={4}
              placeholder={t('showcase_description_placeholder')}
            />
          </Form.Item>
          <Form.Item
            label={t('showcase_label')}
            name="label"
            rules={[{ required: true, message: t('select_required_message') }]}
          >
            <Select
              options={showcaseLabelOptions}
              placeholder={t('showcase_label_placeholder')}
            />
          </Form.Item>
          <Form.Item
            label={t('showcase_banner')}
            name="bannerData"
            rules={[{ required: true, message: t('upload_image_required') }]}
          >
            <ImageUpload
              title={tcommon('upload_image_title')}
              description={tcommon('recommended_image_size', {
                size: '1600x900',
                formats: 'jpeg, jpg, png',
              })}
              crop={{
                quality: 0.8,
                aspect: 160 / 90,
                minZoom: 1,
                maxZoom: 10,
              }}
              onUploadSuccess={(file: UploadedFile) =>
                form.setFieldValue('bannerData', file)
              }
            />
          </Form.Item>
          <Form.Item
            label={t('showcase_link')}
            name="link"
            rules={[{ required: true, message: t('input_required_message') }]}
          >
            <Input placeholder="Paste your link" />
          </Form.Item>
          <Form.Item
            label={t('showcase_button_name')}
            name="buttonName"
            rules={[{ required: true, message: t('input_required_message') }]}
          >
            <Input placeholder={t('showcase_button_name_placeholder')} />
          </Form.Item>
        </div>
        <div className="flex justify-end items-center px-4 py-3 border-t border-solid border-gray-400 gap-2">
          <Button key="back" shape="round" onClick={() => onCancel()}>
            {tcommon('cancel')}
          </Button>
          <Button
            type="primary"
            shape="round"
            loading={createLoading || patchLoading}
            htmlType="submit"
          >
            {tcommon('save')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default ShowcaseEdit;
