import {
  BulbOutlined,
  CodeSandboxOutlined,
  DashboardOutlined,
  FireOutlined,
  PushpinOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  RocketOutlined,
  TagsOutlined,
  TeamOutlined,
  UngroupOutlined,
  UnorderedListOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_ME, Me } from '@frontend/data-access';
import { Layout, Menu } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useState } from 'react';

const Sider: React.FC = () => {
  const { t } = useTranslation('admin');
  const { t: tcommon } = useTranslation('common');
  const { pathname } = useRouter();
  const { data } = useQuery<{ me: Me }>(GET_ME);

  const menuItems = [
    {
      key: 'dashboard',
      label: <Link href="/">Dashboard</Link>,
      icon: <DashboardOutlined />,
      roles: ['ADMIN'],
    },
    {
      key: 'user-management',
      label: <Link href="/user-management">User management</Link>,
      icon: <UserOutlined />,
      roles: ['ADMIN'],
    },
    {
      key: 'company-management',
      label: <Link href="/company-management">Company management</Link>,
      icon: <TeamOutlined />,
      roles: ['ADMIN'],
    },
    {
      key: 'content-management',
      label: (
        <Link href="/content-management">{t('post_management_title')}</Link>
      ),
      icon: <ReadOutlined />,
      roles: ['ADMIN'],
    },
    {
      key: 'pitch-management',
      label: <Link href="/pitch-management">Pitch management</Link>,
      icon: <VideoCameraOutlined />,
      roles: ['ADMIN'],
    },
    {
      key: 'entrepreneurship',
      label: 'Open innovation',
      icon: <RocketOutlined />,
      roles: ['ADMIN'],
      children: [
        {
          key: 'open-challenges',
          label: (
            <Link href="/entrepreneurship/challenge">Open challenges</Link>
          ),
        },
      ],
    },
    {
      key: 'intrapreneurship',
      label: 'Intrapreneurship',
      icon: <BulbOutlined />,
      roles: ['ADMIN', 'CHALLENGE_MANAGER'],
      children: [
        {
          key: 'challenges',
          label: (
            <Link href="/intrapreneurship/challenge">
              {t('intra_challenges')}
            </Link>
          ),
        },
      ],
    },
    {
      key: 'space-management',
      label: <Link href="/space-management">Space management</Link>,
      icon: <UngroupOutlined />,
      roles: ['ADMIN'],
    },
    {
      key: 'sidebar-management',
      label: <Link href="/sidebar-management">Sidebar management</Link>,
      icon: <PushpinOutlined />,
      roles: ['ADMIN'],
    },
    {
      key: 'idea-management',
      label: <Link href="/idea-management">{t('idea_management')}</Link>,
      icon: <FireOutlined />,
      roles: ['ADMIN'],
    },
    {
      key: 'proof-of-concept',
      label: <Link href="/proof-of-concept">Proof of concept</Link>,
      icon: <CodeSandboxOutlined />,
      roles: ['ADMIN'],
    },
    {
      key: 'tags-and-topics',
      label: 'Tags and topics',
      icon: <TagsOutlined />,
      roles: ['ADMIN'],
      children: [
        {
          key: 'topics',
          label: <Link href="/topic-management">{tcommon('Topics')}</Link>,
        },
        {
          key: 'tags',
          label: <Link href="/tag-management">{tcommon('Tags')}</Link>,
        },
      ],
    },
    {
      key: 'audit-log',
      label: <Link href="/audit-log">{t('audit_log')}</Link>,
      icon: <UnorderedListOutlined />,
      roles: ['ADMIN'],
    },
    {
      key: 'guide',
      label: <Link href="/guide">{t('guide')}</Link>,
      icon: <QuestionCircleOutlined />,
      roles: ['ADMIN'],
    },
  ];

  const filteredItems = [];
  if (data?.me) {
    filteredItems.push(
      ...menuItems.filter((item) => item.roles.includes(data.me.role))
    );
  }

  const [page, setPage] = useState<string>(
    pathname.split('/').pop() || (filteredItems.length && filteredItems[0].key)
  );

  useEffect(() => {
    setPage(
      pathname.split('/').pop() ||
        (filteredItems.length && filteredItems[0].key)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Layout.Sider width="auto" breakpoint="lg">
      <Menu
        mode="inline"
        defaultSelectedKeys={[page]}
        defaultOpenKeys={
          pathname?.startsWith('/entrepreneurship')
            ? ['entrepreneurship']
            : pathname?.startsWith('/intrapreneurship')
            ? ['intrapreneurship']
            : []
        }
        style={{ height: '100%', borderRight: 0 }}
        items={filteredItems}
      />
    </Layout.Sider>
  );
};

export default Sider;
