import {
  BulbOutlined,
  CodeSandboxOutlined,
  FireOutlined,
  ReadOutlined,
  RocketOutlined,
  TeamOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  GET_SHOWCASES,
  REMOVE_SHOWCASE,
  Showcase,
} from '@frontend/data-access';
import { getLinkLabel } from '@frontend/shared/util';
import { Button, Image, notification, Popconfirm } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';

import ShowcaseEdit from '../edit';

const getIcon = (key) => {
  switch (key) {
    case 'COMPANY':
      return <TeamOutlined />;
    case 'POST':
      return <ReadOutlined />;
    case 'OPEN_CHALLENGE':
      return <RocketOutlined />;
    case 'INTRA_CHALLENGE':
      return <BulbOutlined />;
    case 'PITCH':
      return <VideoCameraOutlined />;
    case 'IDEA':
      return <FireOutlined />;
    case 'POC':
      return <CodeSandboxOutlined />;
    default:
      break;
  }
};

function CompanyShowcaseCard({ data }: { data: Showcase }) {
  const { t } = useTranslation('admin');
  const { t: tcommon } = useTranslation('common');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleCompleted = () => {
    notification.success({
      message: tcommon('remove_success'),
      placement: 'bottomLeft',
    });
  };
  const [deleteShowcase, { loading: deleteLoading }] = useMutation(
    REMOVE_SHOWCASE,
    {
      onCompleted: handleCompleted,
      refetchQueries: [
        { query: GET_SHOWCASES, variables: { types: [data.type] } },
      ],
    }
  );

  const handleOpenModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleRemove = () => {
    deleteShowcase({
      variables: {
        id: data.id,
      },
    });
  };

  return (
    <div className="p-4 rounded-lg bg-gray-200 flex gap-4">
      <div className="flex items-center">
        <Image
          src={data.banner.url}
          alt={data.title}
          preview={false}
          height={60}
          width={100}
          className="rounded-md"
        />
      </div>
      <div className="flex flex-col justify-center w-full">
        <div className="text-sm text-gray-900 font-semibold">{data.title}</div>
        <span className="text-xs text-gray-700 line-clamp-2 font-normal">
          {data.description}
        </span>
      </div>
      <div className="flex flex-col justify-center w-28">
        <span className="w-full text-xs text-gray-900 font-normal flex gap-1 items-center">
          {getIcon(data.label)}
          {getLinkLabel(data.label.toLowerCase())}
        </span>
      </div>
      <div className="flex items-center">
        <Button type="link" onClick={handleOpenModal}>
          {tcommon('edit')}
        </Button>
        <Popconfirm
          title={t('showcase_delete_confirm')}
          okButtonProps={{
            loading: deleteLoading,
          }}
          onConfirm={handleRemove}
          okText={tcommon('yes')}
          cancelText={tcommon('no')}
        >
          <Button type="link" danger>
            {tcommon('delete')}
          </Button>
        </Popconfirm>
      </div>

      <Modal
        title={t('edit_showcase')}
        open={isOpenModal}
        onCancel={handleOpenModal}
        bodyStyle={{
          padding: 0,
        }}
        footer={null}
      >
        <ShowcaseEdit
          showcaseId={data?.id}
          showcaseType={data?.type}
          onFinish={handleOpenModal}
          onCancel={handleOpenModal}
        />
      </Modal>
    </div>
  );
}

export default CompanyShowcaseCard;
